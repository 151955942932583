@font-face {
  font-family: "Geologica";
  src: local("Geologica"),
    url("./assets/fonts/GeologicaRoman-Regular.ttf") format("ttf")
      url("./assets/fonts/GeologicaRoman-Regular.woff2") format("woff2")
      url("./assets/fonts/GeologicaRoman-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Geologica";
  src: local("Geologica"),
    url("./assets/fonts/GeologicaRoman-Medium.ttf") format("ttf")
      url("./assets/fonts/GeologicaRoman-Medium.woff2") format("woff2")
      url("./assets/fonts/GeologicaRoman-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Geologica", sans-serif;
  color: #3e3e3e;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
